html {
  height: 100%;
  overflow: hidden auto;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Dark-theme color resets */
.chakra-ui-dark {
  --chakra-colors-bgPrimary: var(--chakra-colors-blackAlpha-900);
  --chakra-colors-bgSecondary: var(--chakra-colors-gray-900);

  --chakra-colors-hoverPrimary: var(--chakra-colors-gray-800);

  --chakra-colors-textPrimary: var(--chakra-colors-whiteAlpha-800);
  --chakra-colors-textSecondary: var(--chakra-colors-whiteAlpha-900);
  --chakra-colors-textTertiary: var(--chakra-colors-whiteAlpha-700);
  --chakra-colors-link: var(--chakra-colors-blue-300);

  --chakra-colors-borderColor: var(--chakra-colors-gray-600);
  --chakra-borders-base: 1px solid var(--chakra-colors-borderColor);

  --chakra-colors-shadowColor: var(--chakra-colors-blackAlpha-900);
  --chakra-shadows-base: 0px 4px 15px var(--chakra-colors-shadowColor);
}
